<template>
  <div
    class="member"
    v-loading.fullscreen.lock="dataLoading"
  >
    <div v-if="hasPermission">
      <div class="rightTop">
        <el-button
          class="btnCommon btnComonPlus"
          @click="showCreateMemberForm"
        >新增人员</el-button>
        <el-button
          class="btnCommon"
          @click="toExport"
        ><i class="el-icon-upload2"></i> 导出</el-button>
      </div>
      <div class="simpleInfoPanel">
        <ul class="filtroPanel">
          <li>
            <span>人员：</span>
            <el-input v-model="form.member"></el-input>
          </li>
          <li>
            <span>项目：</span>
            <el-select
              popper-class="w-block-select-down"
              v-model="form.project"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in projectOptionsFiltro"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </li>
          <li class="w-input-sty">
            <span>部门：</span>
            <el-cascader
              popper-class="black-select-panel"
              v-model="form.division"
              placeholder="请选择部门"
              :options="divisionOptions"
              clearable
              :props="{
                expandTrigger: 'hover',
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'division'
              }"
            ></el-cascader>
          </li>
          <li>
            <span>角色：</span>
            <el-select
              popper-class="w-block-select-down"
              v-model="form.role"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <el-button
              class="btnPrimary btnCommon"
              @click="searchByCondition"
            >查询</el-button>
          </li>
        </ul>
      </div>
      <div class="simpleInfoPanel">
        <el-table
          :data="tableData"
          class="tbsWrap"
        >
          <el-table-column
            prop="order"
            label="序号"
            width="80"
          >
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="人员名称"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            label="登录用户名"
          >
          </el-table-column>
          <el-table-column
            prop="project"
            label="项目绑定"
          >
          </el-table-column>
          <el-table-column
            prop="division"
            label="所属部门"
          >
          </el-table-column>
          <el-table-column
            prop="role"
            label="角色"
          >
          </el-table-column>
          <el-table-column
            prop="date"
            label="最近登录时间"
          >
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div
                class="handlePanel"
                v-if="user.userId!==scope.row.id"
              >
                <el-switch
                  active-color="#FFA134"
                  @change="handleMemberState($event, scope.row)"
                  :disabled="scope.row.switch===1"
                  :title="scope.row.switch===1?'人员('+scope.row.name+')已过期':''"
                  v-model="switchValue[scope.row.id]"
                ></el-switch>
                <a @click="handleMember(scope.row.id)">编辑</a>
                <a @click="getDataUserRights(scope.row.id)">权限</a>
                <a @click="resetPassword(scope.row.id)">重置密码</a>
                <a @click="deleteItem(scope.row)">删除</a>
              </div>
              <div v-else>
                当前用户
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div
          class="details-no-data mt80"
          v-else
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无数据</p>
        </div> -->
        <div class="w-page-sty">
          <el-pagination
            background
            layout="total, prev, pager, next"
            v-if="params.total>10"
            :total="params.total"
            :page-size="params.pageSize"
            :current-page="params.pageNum"
            @current-change="handlePages"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog
        class="manager-dialog"
        :title="formMember.id?'编辑人员':'新增人员'"
        :visible.sync="formMemberVisibel"
      >
        <el-form
          ref="formMember"
          :model="formMember"
          class="simpleForm"
          :rules="memberRules"
        >
          <el-form-item
            label="登录用户名："
            :label-width="formMemberLabelWidth"
            prop="account"
          >
            <el-input
              class="form-item-style__a"
              :disabled="formMember.id!==''"
              placeholder="请输入4-20位字母、数字或字母+数字组合"
              v-model="formMember.account"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            label="登录密码："
            :label-width="formMemberLabelWidth"
            prop="password"
          >
            <el-input
              class="form-item-style__a"
              type="password"
              placeholder="请输入8-20位英文大小写+数字组合"
              v-model="formMember.password"
              @change="isChange++"
              @focus="clearPwd"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->
          <el-form-item
            label="员工姓名："
            :label-width="formMemberLabelWidth"
            prop="name"
          >
            <el-input
              class="form-item-style__a"
              placeholder="25个字符以内"
              v-model="formMember.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号码："
            :label-width="formMemberLabelWidth"
            prop="tel"
          >
            <el-input
              class="form-item-style__a"
              placeholder="手机号可以作为登录用户名进行系统登录"
              v-model="formMember.tel"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="邮箱："
            :label-width="formMemberLabelWidth"
            prop="email"
          >
            <el-input
              class="form-item-style__a"
              placeholder="邮箱可接收预警等系统消息"
              v-model="formMember.email"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="绑定项目："
            :label-width="formMemberLabelWidth"
            prop="project"
            placeholder="请选择项目"
          >
            <el-select
              class="form-item-style__b"
              v-model="formMember.project"
              multiple
              placeholder="请选择项目"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="部门选择："
            :label-width="formMemberLabelWidth"
            prop="division"
            placeholder="请选择部门"
          >
            <el-cascader
              v-model="formMember.division"
              class="form-item-style__b"
              placeholder="请选择部门"
              :options="divisionOptions"
              :props="{
                expandTrigger: 'hover',
                checkStrictly: true,
                label: 'name',
                value: 'id',
                children: 'division'
              }"
              @change="toValidateField('formMember', 'division')"
            ></el-cascader>
            <router-link
              target="_blank"
              to="/manageAccount/division"
              class="ml-24 linkbtn"
            >新建部门</router-link>
          </el-form-item>
          <el-form-item
            label="角色选择："
            :label-width="formMemberLabelWidth"
            prop="role"
            placeholder="请选择角色"
          >
            <el-select
              class="form-item-style__b"
              v-model="formMember.role"
              placeholder="请选择角色"
              multiple
              @change="toValidateField('formMember', 'role')"
            >
              <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <router-link
              target="_blank"
              :to="{path: '/manageAccount/role', query: {index:1}}"
              class="ml-24 linkbtn"
            >新建角色</router-link>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="cancelbtn"
            @click="resetMemberForm"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="handleForm"
          >确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="权限查看"
        :visible.sync="userRightsVisible"
        class="rightsPanel w-is-scroll manager-dialog"
        v-loading="loadingRightsTree"
      >
        <p>多重角色相同权限的已合并，拥有权限如下：</p>
        <br>
        <el-tree
          ref="tree"
          node-key="id"
          class="filter-tree fl"
          default-expand-all
          :data="rightsTree"
          :props="{
            label: 'cnname',
            children: 'operateList2VOS'
          }"
        />
        <!-- <el-tree
          :data="rightsTbs"
          :default-expanded-keys="treeCheckedArr"
          :default-checked-keys="treeCheckedArr"
          class="filter-tree task-wrap"
          show-checkbox
          node-key="id"
          ref="tree"
          :props="{
            children: 'configVOS',
            label: 'cnname'
          }">
        </el-tree> -->
      </el-dialog>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
// import { Md5 } from '@/utils/md5'
import { mapGetters } from 'vuex'
export default {
  name: 'member',
  components: {},
  data () {
    const validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录用户名'))
      } else {
        // const testAccount = new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{4,20}$')
        const testAccount = /^[0-9A-Za-z]{4,20}$/
        if (testAccount.test(value)) {
          callback()
        } else {
          callback(new Error('用户名为4-20位英文、数字或英文+数字组合'))
        }
      }
    }
    // const validatePassword = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else {
    //     const testPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
    //     // const testPwd = new RegExp('^[0-9A-Za-z]{8,20}$')
    //     if (testPwd.test(value)) {
    //       callback()
    //     } else {
    //       callback(new Error('密码为8-20位英文大小写+数字组合'))
    //     }
    //   }
    // }
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入员工姓名'))
      } else {
        if (value.length > 25) {
          callback(new Error('25个字符以内'))
        } else {
          callback()
        }
      }
    }
    return {
      form: {
        member: '',
        project: '',
        division: '',
        role: ''
      },
      formMember: {
        id: '',
        account: '',
        // password: '',
        name: '',
        tel: '',
        email: '',
        project: '',
        division: '',
        role: ''
      },
      isChange: 0,
      switchValue: {},
      formMemberLabelWidth: '97px',
      formMemberVisibel: false,
      memberRules: {
        account: [{ required: true, validator: validateAccount, trigger: 'blur' }],
        // password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        name: [{ required: true, validator: validateName, trigger: 'blur' }],
        project: [{ required: true, message: '请选择项目', trigger: 'blur' }],
        division: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      projectOptionsFiltro: [],
      projectOptions: [],
      divisionOptions: [],
      roleOptions: [],
      tableData: [],
      dataLoading: true,
      userRightsVisible: false,
      rightsTree: [],
      loadingRightsTree: false,
      rightsTbs: [],
      userAuthorities: [],
      hasPermission: true,
      hasPermissionAdd: true,
      hasPermissionEdit: true,
      hasPermissionDel: true,
      hasPermissionDisable: true,
      hasPermissionLeadingout: true,
      hasResetPwd: true,
      treeCheckedArr: [],
      serviceInfo: {},
      hasNumCreateMember: true, // 判断是否有新建人员余额
      params: {
        pageNum: 1,
        pageSize: 10,
        pages: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 89
    })
    if (this.userAuthorities.length) {
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.canCreateMember() // 判断是否有可新建人员余额
      this.getServiceInfo() // 客服信息
      this.getDataMemberList() // 人员列表
      this.getDataProjListFiltro() // 筛选项项目options
      this.getDataDivision() // 部门
      this.getDataRoleList() // 筛选项角色options
      // this.getRightsList() // 查看权限tree
      this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
      this.hasPermissionEdit = this.userAuthorities[0].func.indexOf('edit') !== -1
      this.hasPermissionDel = this.userAuthorities[0].func.indexOf('del') !== -1
      this.hasPermissionDisable = this.userAuthorities[0].func.indexOf('disable') !== -1
      this.hasPermissionLeadingout = this.userAuthorities[0].func.indexOf('leadingout') !== -1
      this.hasResetPwd = this.userAuthorities[0].func.indexOf('resetpwd') !== -1
    } else {
      this.hasPermission = false
      this.dataLoading = false
    }
  },
  methods: {
    // 重置密码
    resetPassword (id) {
      // console.log(id)
      if (!this.hasResetPwd) {
        this.hasnotPermission()
        return false
      }
      this.$confirm('密码将被重置为Aa123456，请确认是否执行此操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res1) => {
        console.log(res1)
        if (res1 === 'confirm') {
          this.axios.post(api.resetPwd, { id: id }).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '重置密码成功'
              })
            }
          })
        }
      })
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    canCreateMember () {
      this.axios.post(api.getEditionInterests, {
        id: this.user.userId
      }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.membernum === 0) {
            this.hasNumCreateMember = false
          } else {
            this.hasNumCreateMember = true
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getRightsList () { // 查看权限tree
      const params = {
        roleId: ''
      }
      this.userRightsVisible = true
      this.loadingRightsTree = true
      this.treeCheckedArr = []
      this.axios.post(api.getPermissionsConfigList, params).then(res => { // 这个接口是获取角色权限的
        if (res.data.code === 0) {
          this.rightsTbs = res.data.data.map(item => {
            if (item.isSelect) {
              this.treeCheckedArr.push(item.id)
            }
            if (item.configVOS && item.configVOS.length) {
              let count1 = 0
              for (let i = 0; i < item.configVOS.length; i++) {
                const item1 = item.configVOS[i]
                if (item1.configVOS && item1.configVOS.length) {
                  for (let j = 0; j < item1.configVOS.length; j++) {
                    const item2 = item1.configVOS[j]
                    if (item2.configActions && item2.configActions.length) {
                      item2.configVOS = item2.configActions
                      let count2 = 0
                      for (let k = 0; k < item2.configActions.length; k++) {
                        const item3 = item2.configActions[k]
                        item3.cnname = item3.remark
                        item3.id = item3.action + ',' + item2.id
                        if (item3.isSelect) {
                          count2++
                          this.treeCheckedArr.push(item3.id)
                        }
                      }
                      if (item2.isSelect && count2 === item2.configActions.length) {
                        count1++
                        this.treeCheckedArr.push(item2.id)
                      }
                    }
                  }
                  if (item1.isSelect && count1 === item1.configVOS.length) {
                    this.treeCheckedArr.push(item1.id)
                  }
                } else if (item1.configActions && item1.configActions.length) {
                  console.log('item1', item1)
                  if (item1.configActions && item1.configActions.length) {
                    item1.configVOS = item1.configActions
                    let count2 = 0
                    for (let k = 0; k < item1.configActions.length; k++) {
                      const item3 = item1.configActions[k]
                      item3.cnname = item3.remark
                      item3.id = item3.action + ',' + item1.id
                      if (item3.isSelect) {
                        count2++
                        this.treeCheckedArr.push(item3.id)
                      }
                    }
                    if (item1.isSelect && count2 === item1.configActions.length) {
                      count1++
                      this.treeCheckedArr.push(item1.id)
                    }
                  }
                }
              }
            }
            return item
          })
          this.loadingRightsTree = false
        }
      }, rej => {
        console.log(rej)
        this.loadingRightsTree = false
      }).catch(err => {
        this.loadingRightsTree = false
        console.log(err)
      })
    },
    handleForm () {
      this.$refs.formMember.validate(valid => {
        if (valid) {
          if (this.formMember.id) {
            this.editMember()
          } else {
            this.createMember()
          }
        }
      })
    },
    clearPwd () {
      if (this.isChange === 0) {
        this.formMember.password = ''
      }
      this.isChange++
    },
    getDataRoleList () {
      this.axios.post(api.getRoleList, {
        pageSize: 100
      }).then(res => {
        if (res.data.code === 0) {
          this.roleOptions = res.data.data.dataList
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataDivision (id, index) {
      if (this.formMemberVisibel) {
        this.toValidateField('formMember', 'project')
      }
      this.axios.post(api.departmentManagement, {
        // projectId: typeof id === 'object' ? [...id].shift() : id
      }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.divisionOptions = resData.map(item => {
            if (item.managementVOS && item.managementVOS.length) {
              item.division = item.managementVOS.filter(item1 => {
                if (item1.managementVOS && item1.managementVOS.length) {
                  item1.division = item1.managementVOS.filter(item2 => {
                    if (item2.managementVOS && item2.managementVOS.length) {
                      item2.division = item2.managementVOS.filter(item3 => {
                        if (item3.managementVOS && item3.managementVOS.length) {
                          item3.division = item3.managementVOS.filter(item4 => {
                            return item4.type === 2
                          })
                          if (!item3.division.length) {
                            delete item3.division
                          }
                        }
                        return item3.type === 2
                      })
                      if (!item2.division.length) {
                        delete item2.division
                      }
                    }
                    return item2.type === 2
                  })
                  if (!item1.division.length) {
                    delete item1.division
                  }
                }
                return item1.type === 2
              })
            }
            return item
          })
          this.form.division = ''
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjListFiltro () {
      this.axios.post(api.getUserProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptionsFiltro = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjList (id) {
      this.axios.post(api.getUserProjectList1, { userId: id }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handlePages (val) {
      this.params.pageNum = val
      this.getDataMemberList()
    },
    searchByCondition () {
      this.params.pageNum = 1
      this.getDataMemberList()
    },
    getDataMemberList () {
      this.dataLoading = true
      const params = {
        deploymenId: '',
        projectId: this.form.project,
        roleId: this.form.role,
        userName: this.form.member
      }
      if (typeof this.form.division === 'object') {
        params.deploymenId = [...this.form.division].pop()
      } else {
        params.deploymenId = this.form.division
      }
      Object.assign(this.params, params)
      this.axios.post(api.getZiUserList, this.params).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.dataList.map((item) => {
            const tempObj = {
              id: item.userid,
              name: item.userName,
              project: item.project,
              role: item.role,
              account: item.user,
              division: item.deployment,
              date: item.updatetime,
              switch: item.isStoped
            }
            if (item.isStoped === 2) {
              this.switchValue[item.userid] = true
              tempObj.switch = true
            } else if (item.isStoped === 3) {
              this.switchValue[item.userid] = false
            } else {
              this.switchValue[item.userid] = 1
            }
            return tempObj
          })
          this.params.pageNum = res.data.data.pageNum
          this.params.total = res.data.data.total
          this.dataLoading = false
        }
      })
    },
    toExport () {
      if (!this.hasPermissionLeadingout) {
        this.hasnotPermission()
        return false
      }
      this.dataLoading = true
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      this.axios({
        method: 'GET',
        url: api.get_excel,
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res) {
          const blob = new Blob([res.data])
          const fileName = '人员列表 ' + year + '-' + month + '-' + day + '.xls'
          // 兼容ie
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, fileName)
            this.loading = false
          } else {
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()

            setTimeout(() => {
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            }, 1000)
          }
          this.dataLoading = false
        } else {
          this.dataLoading = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    resetMemberForm () {
      this.isChange = 0
      this.formMemberVisibel = false
      this.formMember = {
        id: '',
        account: '',
        // password: '',
        name: '',
        tel: '',
        email: '',
        project: '',
        division: '',
        role: ''
      }
      this.$nextTick(() => {
        this.$refs.formMember.clearValidate()
      })
    },
    editMember () {
      const params = {
        // exportbrandnum: 0, // 品牌导出数
        look4link: 0, // 品牌联系特权数
        lookbrandrightnum: 0, //  查看品牌权益数
        signednum: 0, // 查看项目权益数
        membernum: 0, // 子账号配置个数
        user: 'string', // 登录用户名
        // passwd: 'string', // 密码(前端md5一次)
        userName: 'string',
        phone: 'string', // 手机号
        projectIds: [], // 绑定项目id
        deploymentId: 0, // 部门id
        roleId: 'string', // 角色id
        userId: 0
      }
      params.userId = this.formMember.id
      params.user = this.formMember.account
      // if (this.formMember.password === 'cccccccc' && this.isChange === 0) {
      //   params.passwd = ''
      // } else {
      //   const md5 = new Md5()
      //   params.passwd = md5.hex_md5(this.formMember.password).toUpperCase()
      // }
      params.userName = this.formMember.name
      params.phone = this.formMember.tel
      params.userEmail = this.formMember.email
      params.projectIds = this.formMember.project
      if (typeof this.formMember.division === 'object') {
        params.deploymentId = [...this.formMember.division].pop()
      } else {
        params.deploymentId = this.formMember.division
      }
      params.roleIds = this.formMember.role
      this.axios.post(api.updateMember, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '编辑成功'
          })
          this.formMemberVisibel = false
          this.resetMemberForm()
          this.getDataMemberList() // 人员列表
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getServiceInfo () {
      this.axios.post(api.getKeFuwetchatCode, {}).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.serviceInfo = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    showCreateMemberForm () {
      if (!this.hasPermissionAdd) {
        this.hasnotPermission()
        return false
      }
      if (!this.hasNumCreateMember) {
        this.$message({
          type: 'warning',
          message: '子账号创建数量已达上限，如需增加请加赢商客服微信咨询：' + this.serviceInfo.kefuwechatname
        })
        return false
      }
      this.getDataProjList()
      this.resetMemberForm()
      this.formMemberVisibel = true
    },
    createMember () {
      this.$refs.formMember.validate((valid) => {
        if (valid) {
          const params = {
            // exportbrandnum: 0, // 品牌导出数
            look4link: 0, // 品牌联系特权数
            lookbrandrightnum: 0, // 查看品牌权益数
            membernum: 0, // 子账号配置个数
            signednum: 0, // 查看项目权益数
            user: 'string', // 登录用户名
            // passwd: 'string', // 密码(前端md5一次) ,
            userName: 'string', // 员工姓名
            phone: 'string', // 手机号
            projectIds: [], // 绑定项目id
            deploymentId: '',
            roleId: 'string' // 角色id（多个用逗号隔开） ,
          }
          // const md5 = new Md5()
          params.user = this.formMember.account
          // params.passwd = md5.hex_md5(this.formMember.password).toUpperCase()
          params.userName = this.formMember.name
          params.phone = this.formMember.tel
          params.userEmail = this.formMember.email
          params.projectIds = this.formMember.project
          if (typeof this.formMember.division === 'object') {
            params.deploymentId = [...this.formMember.division].pop()
          } else {
            params.deploymentId = this.formMember.division
          }
          params.roleIds = this.formMember.role
          this.axios.post(api.saveMember, params).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '添加人员成功'
              })
              this.resetMemberForm()
              this.getDataMemberList() // 人员列表
            } else {
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleMember (id) {
      this.getDataProjList(id)
      if (!this.hasPermissionEdit) {
        this.hasnotPermission()
        return false
      }
      this.isChange = 0 // 判断是否修改了密码
      if (id) {
        this.axios.post(api.getById, {
          userId: id
        }).then(res => {
          const resData = res.data.data
          this.formMember.id = resData.userId
          this.formMember.account = resData.user
          // this.formMember.password = 'cccccccc'
          this.formMember.name = resData.userName
          this.formMember.tel = resData.phone
          this.formMember.email = resData.userEmail
          this.formMember.project = resData.projects.map(item => {
            return item.id
          })
          this.formMember.division = resData.deploymentIds ? resData.deploymentIds.split(',') : ''
          if (resData.deploymentIds) {
            this.formMember.division = resData.deploymentIds.split(',').map(item => {
              return parseInt(item)
            })
          } else {
            this.formMember.division = ''
          }
          if (resData.roleId) {
            this.formMember.role = resData.roleId.split(',').map(item => {
              item = parseInt(item)
              return item
            })
          } else {
            this.formMember.role = ''
          }
          this.formMemberVisibel = true
          this.$nextTick(() => {
            this.$refs.formMember.clearValidate()
          })
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    tellme (val) {
      console.log('val', val)
    },
    toValidateField (formName, field) {
      this.$refs[formName].validateField(field, err => { // 验证手机号码是否正确
        if (!err) {
        } else {
          return false
        }
      })
    },
    getDataUserRights (id) {
      this.axios.post(api.getUserRoleOperateList, {
        userid: id
      }).then(res => {
        if (res.data.code === 0 && res.data.data) {
          // this.rightsTree = res.data.data.map(item => {
          //   for (let i = 0; i < item.operateList2VOS.length; i++) {
          //     const element = item.operateList2VOS[i]
          //     item.operateList2VOS[i].operateList2VOS = element.configActions.map(item1 => {
          //       item1.cnname = item1.remark
          //       return item1
          //     })
          //   }
          //   return item
          // })
          this.userRightsVisible = true
          this.rightsTree = res.data.data.map(item => {
            // for (let i = 0; i < item.operateList2VOS.length; i++) {
            //   const element = item.operateList2VOS[i]
            //   item.operateList2VOS[i].operateList2VOS = element.configActions.map(item1 => {
            //     item1.cnname = item1.remark
            //     return item1
            //   })
            // }
            return item
          })
          this.rightsTree = this.rightsTree.filter(item => {
            return item.cnname
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleMemberState (key, data) {
      if (!this.hasPermissionDisable) {
        this.hasnotPermission()
        return false
      }
      this.axios.post(api.updateUsersState, {
        userId: data.id
      }).then(res => {
        if (res.data.code === 0) {
          if (key) {
            this.$message({ type: 'success', message: `账户 ${data.name} 已启用` })
          } else {
            this.$message({ type: 'info', message: `账户 ${data.name} 已禁用` })
          }
          this.getDataMemberList()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleSuccess (resData) {
      if (resData.code === 0) {
        this.$message({
          type: 'success',
          message: resData.msg
        })
        this.getDataMemberList() // 人员列表
        this.dialogFormVisible = false
      }
    },
    deleteItem (data) {
      if (!this.hasPermissionDel) {
        this.hasnotPermission()
        return false
      }
      const params = {
        userId: data.id
      }
      this.$confirm('是否删除成员 ' + data.name, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post(api.deleteUser, params).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            console.log(resData)
            this.handleSuccess(res.data)
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.member
  position relative
  .rightTop
    position absolute
    right 20px
    top -50px
  .rightsPanel>>>
    .el-dialog
      min-height 50vh
      padding-bottom 30px
      overflow-y scroll
      .el-tree
        width 60%
.manager-dialog>>>
  .el-dialog
    width 501px
.member>>>
  .el-form--inline .el-form-item
    margin-right 20px
</style>
